import React from "react"

import Layout from "@layout/layout"
import SEO from "@layout/seo";

export default () => {
    if (typeof window !== 'undefined') {
        window.location = 'https://rejestracja.medchart.pl/8bd3cbc2'
    }
    return (<></>)
}
